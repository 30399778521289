import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Collections } from '../../constants/firestore';
import { useDB } from '../../contexts/FirebaseContext'
import { useRestaurant } from '../../contexts/RestaurantContext';
import { Order, OrderState } from '../../models/Order';
import { fromFirebaseDocs } from '../../utils/shared/firebase';
import { ESTIMATE_TYPE, FEATURE } from '../../constants/features';

const SIZE = '40px';

const dateToMins = (date: Date) => date.getTime() / 1000 / 60;

interface Props {
    showTime?: boolean
}

export default function BusyIndicator(props: Props) {
    const [latestPickupMins, setLatestPickupMins] = useState<number>(0);
    const [nowMins, setNowMins] = useState<number>(0);
    const [queueSize, setQueueSize] = useState<number>(0);
    const db = useDB();
    const restaurant = useRestaurant();
    const estimatesType = useMemo(() => restaurant.features?.[FEATURE.ESTIMATE_TYPE] || ESTIMATE_TYPE.QUEUE, [restaurant.features]);

    useEffect(() => {
        const colRef = collection(db, Collections.restaurants, restaurant.id, Collections.orders);

        const q = estimatesType === ESTIMATE_TYPE.QUEUE
            ? query(colRef,
                where('state', 'in', [OrderState.PAID, OrderState.IN_PROGRESS]))
            : query(colRef,
                orderBy('estimate', 'desc'),
                where('state', 'in', [OrderState.PAID, OrderState.IN_PROGRESS]),
                limit(1));

        return onSnapshot(q, snapshot => {
            if (estimatesType === ESTIMATE_TYPE.QUEUE) {
                setQueueSize(snapshot.docs.length);
                return;
            }

            if (snapshot.empty) {
                return;
            }

            const order = fromFirebaseDocs<Order>(snapshot.docs)[0];
            if (!order.estimate) {
                return;
            }
            const minutes = dateToMins(order.estimate)
            setLatestPickupMins(minutes);
        });

    }, [db, restaurant.id, estimatesType])

    useEffect(() => {
        const updateNowMins = () => {
            const newNowMinutes = dateToMins(new Date())
            setNowMins(newNowMinutes);
        }
        const interval = setInterval(updateNowMins, 1000 * 30);
        updateNowMins();
        return () => clearInterval(interval);
    }, [])

    let title = '0 - 10 minutes';
    let classNames = 'rounded-circle';

    if (restaurant.busyUntil || estimatesType === ESTIMATE_TYPE.TIME) {
        let busyUntilMins = nowMins;
        if (restaurant.busyUntil) {
            busyUntilMins = dateToMins(restaurant.busyUntil);
        }
        const minutesToLatestOrder = Math.max(busyUntilMins, latestPickupMins) - nowMins;

        if (minutesToLatestOrder < 10) {
            classNames += ' bg-success';
        } else if (minutesToLatestOrder < 20) {
            classNames += ' bg-warning';
            title = '10 - 20 minutes';
        } else {
            classNames += ' bg-danger';
            title = '20+ minutes';
        }
    } else {
        title = '0 - 2 orders in queue';
        if (queueSize <= 2) {
            classNames += ' bg-success';
        } else if (queueSize <= 5) {
            classNames += ' bg-warning';
            title = '2 - 5 orders in queue';
        } else {
            classNames += ' bg-danger';
            title = '5+ orders in queue';
        }

    }

    return (
        <>
            <OverlayTrigger
                trigger={props.showTime ? [] : ["hover", 'click']}

                placement="left"
                overlay={
                    <Tooltip >{title}</Tooltip>
                }
            >
                <div className='center'>
                    <div className={classNames} style={{ width: SIZE, height: SIZE }} title={title} />
                    {props.showTime && <span className="ms-2 bold">{title}</span>}
                </div>

            </OverlayTrigger>
        </>
    )
}
