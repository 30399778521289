import { Cart, CartItem } from "../../models/Cart";
import Deal, { DealType } from "../../models/Deal";
import { Order } from "../../models/Order";
import Product from "../../models/Product";
import UserDealTracker from "../../models/UserDealTracker";
import { calculateVatPart } from "./converters";

interface CartSum {
    perVatRateVatAmount: Record<number, number>
    perVatSums: Record<number, number>,
    itemsSum: number,
    discount: number,
    sum: number
    vat: number,
    netto: number,
    tip: number
}

export const DEFAULT_VAT_RATE = 12;

export const sumCart = (cart: Cart | Order): CartSum => {
    const perVatSums: Record<number, number> = {};
    if (cart?.items.length) {
        for (const item of cart.items) {
            const vatRate = Number(item.vatRate) || DEFAULT_VAT_RATE;
            if (!perVatSums[vatRate]) {
                perVatSums[vatRate] = 0;
            }
            perVatSums[vatRate] += item.itemPrice * item.quantity;
        }
    }

    let itemsSum = 0;
    let vat = 0;
    const perVatRateVatAmount: Record<number, number> = {};
    for (const vatRate of Object.keys(perVatSums)) {
        const vatRateNum = Number(vatRate);
        itemsSum += perVatSums[vatRateNum];
        perVatRateVatAmount[vatRateNum] = calculateVatPart(perVatSums[vatRateNum], vatRateNum);
        vat += perVatRateVatAmount[vatRateNum];
    }


    const discount = _sumCartCalculateDiscount(cart, itemsSum);
    const discountVat = calculateVatPart(discount, DEFAULT_VAT_RATE);
    vat -= discountVat;

    let sum = itemsSum - discount;
    let tip = 0;
    if (cart?.tip) {
        tip = Math.round((sum / 100) * cart.tip)
        sum += tip;
    }

    const netto = sum - vat - tip;

    return {
        perVatRateVatAmount,
        perVatSums,
        itemsSum,
        discount,
        tip,
        sum,
        vat,
        netto,
    }
};

const _sumCartCalculateDiscount = (cart: Cart | Order, itemsSum: number): number => {
    let discount = 0;

    if (cart?.deals) {
        for (const deal of cart.deals) {
            if (deal.type === DealType.WHOLE_CART_PERCENTAGE) {
                discount += Math.round((itemsSum / 100) * (deal.discount || 0))
            } else if (deal.type === DealType.STAMP_CARD) {
                const itemsPerPrice = [...cart.items]
                    .filter(item => deal.validProducts?.includes(item.productId));
                itemsPerPrice.sort((a, b) => a.itemPrice - b.itemPrice);
                let remainingFreeItems = deal.discount || 0;
                let currentItemIx = 0;
                while (remainingFreeItems > 0) {
                    const item = itemsPerPrice[currentItemIx];
                    const freeItemsForItem = Math.min(remainingFreeItems, item.quantity);
                    discount += freeItemsForItem * item.itemPrice;
                    remainingFreeItems -= freeItemsForItem;
                    currentItemIx++;
                }
            } else {
                throw new Error("Unknown deal");
            }
        }
    }
    return discount;
}

export const productPrice = (product: Product, choices: string[]) => {
    let price = product.basePrice;

    for (const option of product.options || []) {
        for (const choice of option.choices || []) {
            if (choices.includes(choice.id)) {
                price += choice.priceAdjustment || 0;
            }
        }
    }

    return price;
};

export const generateItemId = (productId: string, choices: string[]) => {
    const sorted = [...choices].sort();
    return productId + '|' + JSON.stringify(sorted);
};

export const generateItemName = (product: Product, choices: string[]) => {
    let name = product.name;

    if (choices.length) {
        const allChoices = (product.options || []).map(option => (option.choices || [])).flat();
        const abbreviations = allChoices
            .filter(choice => choices.includes(choice.id))
            .map(choice => choice.name.length > 0 ? choice.name.substr(0, 1) : 'x')
            .map(abbreviation => `${abbreviation}.`)
            .join(' ');
        name = `${name} (${abbreviations})`;
    }

    return name;
};

export const areAllMandatoryChoosen = (product: Product, choices: string[]): boolean => {
    const mandatoryOptions = (product.options || []).filter(option => option.mandatory);
    for (const option of mandatoryOptions) {
        const found = (option.choices || []).find(choice => choices.includes(choice.id));

        if (!found) {
            return false;
        }
    }


    return true;
};

export const getProductDefaults = (product: Product): string[] => {
    const defaults = product.options?.map(option =>
        (option.choices || [])
            .filter(choice => choice.default)
            .map(choice => choice.id)

    ).flat() || [];
    return defaults;
}

export const calculateStampDealIncrease = (cart: Cart | Order, deals: Deal[]): Record<string, number> => {
    const result: Record<string, number> = {};

    if (!cart.uid) {
        // No stamps for anonymous users
        return result;
    }

    for (const deal of deals) {
        if (deal.type !== DealType.STAMP_CARD) {
            continue;
        }
        const dealIncrease = cart.items
            .filter(item => deal.validProducts?.includes(item.productId))
            .map(item => item.quantity)
            .reduce((sum, current) => sum + current, 0);
        if (!dealIncrease) {
            continue;
        }

        result[deal.id] = dealIncrease;
    }

    return result;
}

export const calculateDealTrackerUpdate = (oldDealTracker: UserDealTracker, deal: Deal, increase: number) => {
    const newDealTracker = { ...oldDealTracker };

    const stampsPerCardIncludingFree = (deal.requiredCount || 0) + 1;

    if (increase + newDealTracker.stampCount >= stampsPerCardIncludingFree) {
        newDealTracker.filledCards += 1;
        newDealTracker.stampCount = 0;
    } else {
        newDealTracker.stampCount += increase;
    }

    return newDealTracker;
}

interface DealsUpdate {
    deals: Deal[],
    changed: boolean
}
export const maybeUpdateStampDealsOnCart = (oldDeals: Deal[], freeItemCount: number, deal: Deal): DealsUpdate => {
    const discountOnOrder = oldDeals.find(_deal => _deal.id === deal.id);


    let changed = false;
    let newDeals: Deal[] = [...oldDeals];
    if (freeItemCount) {
        if (discountOnOrder) {
            if (discountOnOrder.discount !== freeItemCount) {
                discountOnOrder.discount = freeItemCount;
                changed = true;
            }
        } else {
            newDeals.push({
                ...deal,
                discount: freeItemCount,
            })
            changed = true;
        }
    } else {
        if (discountOnOrder) {
            newDeals = newDeals.filter(_deal => _deal.id !== deal.id)
            changed = true;
        }
    }

    return {
        deals: newDeals,
        changed,
    }
}

export const sortCartItems = (cartItems: CartItem[], allProducts: Product[], categoryOrder: string[]): CartItem[] => {
    const sorted = [...cartItems];

    sorted.sort((a, b) => {
        const productA = allProducts.find(product => product.id === a.productId);
        const productB = allProducts.find(product => product.id === b.productId);

        if (!productA || !productB) {
            return 0;
        }

        const categoryA = productA.category || '';
        const categoryB = productB.category || '';

        const categoryIxA = categoryOrder.indexOf(categoryA);
        const categoryIxB = categoryOrder.indexOf(categoryB);

        if (categoryIxA < 0 && categoryIxB >= 0) {
            return 1;
        } else if (categoryIxA >= 0 && categoryIxB < 0) {
            return -1;
        } if (categoryIxA < categoryIxB) {
            return -1;
        } else if (categoryIxA > categoryIxB) {
            return 1;
        }

        const orderA = productA.order || 0;
        const orderB = productB.order || 0;

        if (orderA < orderB) {
            return -1;
        } else if (orderA > orderB) {
            return 1;
        }

        return productA.name.localeCompare(productB.name);
    });


    return sorted;
}
