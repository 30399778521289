import { useEffect, useState } from 'react'
import { CUSTOMER_NAV } from '../../constants/routes';
import { useCart } from '../../contexts/CartContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext';
import { useBackend } from '../../hooks/useBackend';
import { generateSwishAppLink } from '../../utils/swish';
import { usePublicTerminal } from '../../contexts/PublicTerminalContext';
import { PAYMENT_TYPES } from '../../utils/shared/constants';
import { useDB } from '../../contexts/FirebaseContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { fromFirebaseDoc } from '../../utils/shared/firebase';
import { Order, OrderState } from '../../models/Order';
import { Navigate } from 'react-router-dom';
import { getRestaurantPath } from '../../utils/restaurant';
import { AdvancedButton } from '../AdvancedButton';
import { Button } from 'react-bootstrap';

export default function CheckoutPaymentSwish() {
    const text = useText();
    const restaurant = useRestaurant();
    const [processing, setProcessing] = useState(false);
    const { initPayment, handleInitPaymentError, getQR } = useBackend();
    const { cart } = useCart();
    const { isPublicTerminal } = usePublicTerminal();
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [qrCodeOrderId, setQrCodeOrderId] = useState('');
    const [qrCodeOrder, setQrCodeOrder] = useState<Order | null>(null);
    const db = useDB();

    useEffect(() => {
        if (db && qrCodeOrderId && restaurant.id) {
            return onSnapshot(doc(db, "restaurants", restaurant.id, "orders", qrCodeOrderId), (doc) => {
                const newOrder = fromFirebaseDoc<Order>(doc)
                setQrCodeOrder(newOrder);
            });
        }
    }, [db, qrCodeOrderId, restaurant.id]);

    const handleSwish = async () => {
        try {
            setProcessing(true);
            const result = await initPayment(cart, restaurant.sysid, PAYMENT_TYPES.SWISH);
            const swishAppLink = generateSwishAppLink(result.orderId, restaurant, result.paymentId);

            window.location.href = swishAppLink;
        } catch (e) {
            handleInitPaymentError(e)
        }
        setProcessing(false);
    }

    const handleSwishQR = async () => {
        try {
            setProcessing(true);
            const result = await initPayment(cart, restaurant.sysid, PAYMENT_TYPES.SWISH);
            const imageBlob = await getQR(result.paymentId);
            const imageUrl = URL.createObjectURL(imageBlob);
            setQrCodeUrl(imageUrl);
            setQrCodeOrderId(result.orderId)
        } catch (e) {
            handleInitPaymentError(e)
        }
        setProcessing(false);
    }

    if (qrCodeOrder?.state === OrderState.PAID) {
        return <Navigate to={getRestaurantPath(restaurant, `/${CUSTOMER_NAV.CONFIRM}/${qrCodeOrderId}`)} />
    }


    if (qrCodeUrl) {
        return <img className="qr-code" src={qrCodeUrl} alt={text.swish} />
    }

    return <div className='center flex-column gap'>

        {!isPublicTerminal && <AdvancedButton variant='swish' onClick={handleSwish} icon="bi-coin"
            processing={processing}
        >
            {text.payBySwish}
        </AdvancedButton>}
        <Button variant='outline-secondary' size='sm' onClick={handleSwishQR}>
            <i className="bi bi-qr-code" /> {text.swishOnOtherDevice}
        </Button>
    </div>

}
